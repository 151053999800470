<template>
  <div class="is-flex is-justify-content-space-between has-text-grey py-3 is-size-7">
    <div>{{ appName }} - v{{ version }}</div>
    <div>&copy; {{ year }} | <a href="https://www.toppharm.ch/datenschutzerkl%C3%A4rung">Datenschutzerklärung</a></div>
  </div>
</template>

<script>
import { version } from '../../package.json'

export default {
  data () {
    return {
      appName: process.env.VUE_APP_TITLE,
      year: this.$dayjs().format('YYYY'),
      version
    }
  }
}
</script>
