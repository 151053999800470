<template>
  <div>
     <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
          <img src="@/assets/images/logos/LogoTpApoSmall.png" alt="TopPharm Genossenschaft Logo">
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ path: '/audits' }">Audits</b-navbar-item>
        <b-navbar-dropdown :label="manual">
          <b-navbar-item value="M" tag="router-link" :to="{ name: 'Manual', params: { cat: 'M' } }">Handbuch</b-navbar-item>
          <b-navbar-item value="A" tag="router-link" :to="{ name: 'Manual', params: { cat: 'A' } }">Audit</b-navbar-item>
          <b-navbar-item value="S" tag="router-link" :to="{ name: 'Manual', params: { cat: 'S' } }">Selbstevaluation</b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="router-link" :to="{ name: 'Help' }">Legende</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Howto' }">Anleitungen</b-navbar-item>
        <b-navbar-item tag="a" :href="dmsLink" target="_blank" class="has-text-weight-bold has-background-light">Link zum DMS <b-icon icon="external-link-alt" custom-size="xs" class="pl-3" /></b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="div">
          <small v-if="updateExists" @click="refreshApp" class="mr-2 is-clickable">
            <b class="has-text-warning"><b-icon icon="exclamation" type="is-warning" />app update möglich</b>
          </small>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <div>
            <OnlineStatus />
          </div>
        </b-navbar-item>
        <b-navbar-item tag="div"><b-icon icon="user-circle" /><span class="ml-1">{{ user ? user.name : '' }}</span></b-navbar-item>
        <b-navbar-item v-if="isOnline" @click="logout">
          <b-icon icon="sign-out-alt" /><span class="ml-1">Log out</span>
        </b-navbar-item>
        <div v-else class="is-flex is-align-items-center has-text-grey is-unselectable" style="cursor: not-allowed;"><b-icon icon="sign-out-alt" /><span class="ml-1">Log out</span></div>
      </template>
    </b-navbar>
    <div v-if="hasRole('QmsAdmin')" class="is-flex is-flex-direction-row-reverse px-3">
      <Impersonate />
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

import ManualService from '@/services/manual.service'
import UserService from '@/services/user.service'

import OnlineStatus from './OnlineStatus.vue'
import Impersonate from './Impersonate.vue'

import update from '@/mixins/update'

export default {
  components: {
    OnlineStatus,
    Impersonate
  },
  mixins: [update],
  data () {
    return {
      manual: 'Kriterien',
      menus: [
        { text: 'Handbuch', path: '/manual/M' },
        { text: 'Audit', path: '/manual/A' },
        { text: 'Selbstevaluation', path: '/manual/S' }
      ],
      dmsLink: process.env.VUE_APP_DMS_URL
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        const curMenu = this.menus.find(p => p.path === to.path)
        this.manual = (curMenu && curMenu.text) ? `Kriterien - ${curMenu.text}` : 'Kriterien'
      }
    }
  },
  mounted () {
    ManualService.getManual(2024)
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      hasRole: 'auth/hasRole',
      isOnline: 'global/isOnline'
    })
  },
  methods: {
    logout () {
      this.$buefy.dialog.confirm({
        message:
          'Möchtest du wirklich ausloggen ?<br>Somit wirst du bei allen TopPharm Webapplikationen ausgeloggt',
        onConfirm: () => UserService.signOut()
      })
    }
  }
}
</script>
